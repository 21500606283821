<template>
  <edit-info :editInfoList=editInfoList @submitfn="submitForm"></edit-info>
</template>

<script>
import EditInfo from '../BaseInfo/editInfo'
import {editInformation,getUserInfo} from '@/api';
import {isPhoneNum,isNumber} from '@/utils/validate'
export default {
  components: { EditInfo },
  data(){
    return {
      editInfoList:[
        {name:"项目公司名称", field:"name"},
        {name:"管理员姓名", field:"people_name"},
        {name:"联系电话", field:"phone_number"},
        {name:"总包部数量", field:"company_num",disable:true},
        {name:"项目部数量", field:"department_num",disable:true},
        {name:"监督岗数量", field:"sentry_num",disable:true},
      ]
    }
  },
  methods:{
    async submitForm(form){
     try{
          if(!isPhoneNum(form.phone_number)) {
            throw new Error("手机格式错误")
          }
          if(!isNumber(form.company_num)) {
            throw new Error("公司总数为格式错误")
          }
          if(!isNumber(form.department_num)) {
            throw new Error("部门总数为格式错误")
          }
          if(!isNumber(form.sentry_num)) {
            throw new Error("监督岗总数为格式错误")
          }
            const requestData = {
              type:1,
              value:form
            }
          const res = await editInformation(requestData);
          if(res.status == 200) {
            const res = await getUserInfo();
            const userInfo = res.data.data
            localStorage.setItem("userInfo",JSON.stringify(userInfo))
            this.$success("修改成功")
            this.$router.back()
          }
        }catch (e) {
          if(e instanceof Error){
            this.$message(e.message)
          }
        }
    }
  }
}
</script>

<style scoped>

</style>
