<template>
  <right-content>
    <div class="baseInfo">
      <div class="info-image">
        <upload-img :selectImg.sync="userImg"></upload-img>
      </div>
      <div class="info-content">
        <el-descriptions :column="2" border>
          <el-descriptions-item
            :label="item.name"
            v-for="(item, index) in editInfoList"
            :key="index"
          >
            <el-date-picker v-if="item.field === 'found_time'"
                            v-model="form[item.field]"
                            type="datetime"
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                            placeholder="选择日期时间"
                            prefix-icon
            >
            </el-date-picker>
            <el-input :disabled="item.disable" v-else @input="elInput" v-model="form[item.field]">
            </el-input>
          </el-descriptions-item>
        </el-descriptions>
      </div>
      <el-button class="subBtn" @click="submitForm">提交</el-button>
    </div>
  </right-content>
</template>

<script>
import UploadImg from "@/components/UploadImg";
import RightContent from '../commonViews/RightContent';
export default {
  name: "EditInfo",
  data() {
    return {
      userImg: "",
      editLists: [],
      userInfo: "",
      form: {},
    };
  },
  props: {
    editInfoList: {
      type: Array,
    },
  },
  components: {
    RightContent,
    UploadImg,
  },
  watch: {
    editInfoList: {
      handler(val) {
        this.editLists = val;
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    const info = this.$route.query.info;
    if (info) {
      const userInfo = JSON.parse(this.$route.query.info);
      this.$nextTick(() => {
        this.userImg = userInfo.img;
      });
      this.editInfoList.forEach((item) => {
        const field = item.field;
        this.form[field] = userInfo[field];
      });
      this.userInfo = userInfo;
    }
  },
  methods: {
    submitForm() {
      this.form.img = this.userImg;
      this.form.id = this.userInfo.id;
      this.$emit("submitfn", this.form);
    },
    elInput() {
      this.$forceUpdate();
    },
  },
};
</script>

<style lang="scss" scoped>
.baseInfo {
  margin: 30px auto;
  width: 60vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  .info-image {
    width: 100px;
    height: 100px;
    margin-bottom: 50px;
    border-radius: 50%;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .info-content {
    margin-top: 30px;
    width: 100%;
    .option-content {
      display: flex;
      justify-content: center;
      height: 60px;
      .el-input {
        width: 100%;
      }
    }
  }
  .subBtn {
    margin-top: 30px;
    padding: 10px 100px;
    background-color: red;
    border-radius: 5px;
    color: #fff;
  }
}
::v-deep .el-descriptions-item__label {
  background-color: darkgray;
  width: 200px;
  height: 40px !important;
  font-weight: 100;
  color: black;
}
::v-deep .el-descriptions-item__content {
  display: flex;
  justify-content: space-between;
}

::v-deep .el-input__inner {
  border: none;
  padding: 0;
  height: 20px;
}
  .resetPwd{
    background: red;
    color: #fff;
    border-radius: 8px;
    border: none;
    outline: none;
    padding: 0 10px;
  }
</style>
